body{
  color: #fff;
  font-size: 17px;
  font-family: 'Exo';
}
body::-webkit-scrollbar-track
{
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.8); */
  border-radius: 30px;
  background-color: #777777;
}

body::-webkit-scrollbar
{
  width: 10px;
  background-color: #777777;
}

body::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.8); */
  background-color: rgb(0, 0, 0);
}

@font-face {
  font-family: 'Long Shot';
  src: url('../../public/webfonts/LongShot.eot');
  src: url('../../public/webfonts/LongShot.eot?#iefix') format('embedded-opentype'),
      url('../../public/webfonts/LongShot.woff2') format('woff2'),
      url('../../public/webfonts/LongShot.woff') format('woff'),
      url('../../public/webfonts/LongShot.ttf') format('truetype'),
      url('../../public/webfonts/LongShot.svg#LongShot') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Exo';
  src: url('../../public/webfonts/Exo-Regular.eot');
  src: url('../../public/webfonts/Exo-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../public/webfonts/Exo-Regular.woff2') format('woff2'),
      url('../../public/webfonts/Exo-Regular.woff') format('woff'),
      url('../../public/webfonts/Exo-Regular.ttf') format('truetype'),
      url('../../public/webfonts/Exo-Regular.svg#Exo-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Exo';
  src: url('../../public/webfonts/Exo-Medium.eot');
  src: url('../../public/webfonts/Exo-Medium.eot?#iefix') format('embedded-opentype'),
      url('../../public/webfonts/Exo-Medium.woff2') format('woff2'),
      url('../../public/webfonts/Exo-Medium.woff') format('woff'),
      url('../../public/webfonts/Exo-Medium.ttf') format('truetype'),
      url('../../public/webfonts/Exo-Medium.svg#Exo-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Exo';
  src: url('../../public/webfonts/Exo-SemiBold.eot');
  src: url('../../public/webfonts/Exo-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../../public/webfonts/Exo-SemiBold.woff2') format('woff2'),
      url('../../public/webfonts/Exo-SemiBold.woff') format('woff'),
      url('../../public/webfonts/Exo-SemiBold.ttf') format('truetype'),
      url('../../public/webfonts/Exo-SemiBold.svg#Exo-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Exo';
  src: url('../../public/webfonts/Exo-Bold.eot');
  src: url('../../public/webfonts/Exo-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../public/webfonts/Exo-Bold.woff2') format('woff2'),
      url('../../public/webfonts/Exo-Bold.woff') format('woff'),
      url('../../public/webfonts/Exo-Bold.ttf') format('truetype'),
      url('../../public/webfonts/Exo-Bold.svg#Exo-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
} 
body{
  cursor: url(../../public/images/cursor-arrow.png), auto !important;
}
a, button, .swiper-slider-nav img, .flipbook-nav img{
  cursor: url(../../public/images/hand.svg), auto !important;
}
@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}
@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-5px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(4px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-2px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(1px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}
/* Common CSS start */
a{
  transition: 0.3s;
}
a:hover{
  color: #FFB400;
  text-decoration: none;
  outline: none;
}
h1,h2,h3,h4,h5,h6{
  font-family: 'Long Shot';
  font-weight: 400;
}
p{
  font-family: 'Exo';
  font-weight: 400;
}
a:focus,
input:focus,
button:focus,
a:hover,
input:hover,
button:hover,
.form-control:focus,
.form-control:hover{
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent;
}
.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active{
  background-color: transparent !important;
  background-image: url("../../public/images/button-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: none;
font-size: 34px;
letter-spacing: 0.04em;
text-align: center;
color: #954110 !important;
text-shadow: -2px 2px 0px #fdde09;
padding: 12px 54px;
text-transform: uppercase;
font-family: 'Long Shot';
}
.btn-primary:hover{
  animation:pulse 0.5s ease-in-out;
}
.btn-primary2{
  background-image: url("../../public/images/button-bg2.png");
}
.main-title{
font-family: 'Long Shot';
font-size: 58px;
letter-spacing: 0.04em;
color: #ffb400;
text-shadow: -3px 3px 0px #8b461f;
text-transform: uppercase;
}
.main-title--small{
  font-family: "Long Shot";
font-size: 50px;
letter-spacing: 0.04em;
color: #ffb400;
text-shadow: -3px 3px 0px #712f0a;
text-transform: uppercase;
}
.section-blur-effect{
  position: relative;
}
.section-blur-effect:before{
  position: absolute;
  content: "";
  width: 100%;
  height: 50px;
  background: #060c05;
  filter: blur(13.257399559020996px);
  left: 0;
  bottom: -25px;
  pointer-events: none;
}
.section-blur-effect:after{
  position: absolute;
  content: "";
  width: 100%;
  height: 50px;
  background: #060c05;
  filter: blur(13.257399559020996px);
  left: 0;
  top: -25px;
  z-index: 1;
  pointer-events: none;
}
.btn-hide {
  display: none;
}
.btn-show {
  display: inline-flex;
}
/* Common CSS end */
/* Header css start */
/*Hamburger css start*/
.hamburger {
  padding: 10px 15px 5px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  -webkit-transition-property: opacity, -webkit-filter;
  -o-transition-property: opacity, filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger-box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 30px;
  height: 4px;
  background-color: #ffb400;
  border-radius: 4px;
  position: absolute;
  transition-property: -webkit-transform;
  -webkit-transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}
.hamburger--emphatic {
  overflow: hidden;
}
.hamburger--emphatic .hamburger-inner {
  -webkit-transition: background-color 0.2s 0.25s ease-in;
  -o-transition: background-color 0.2s 0.25s ease-in;
  transition: background-color 0.2s 0.25s ease-in;
}
.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: top 0.05s 0.2s linear, left 0.2s 0.25s ease-in,
    -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  -webkit-transition: top 0.05s 0.2s linear, left 0.2s 0.25s ease-in,
    -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  -o-transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.2s linear, left 0.2s 0.25s ease-in;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.2s linear, left 0.2s 0.25s ease-in;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.2s linear, left 0.2s 0.25s ease-in,
    -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}
.hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: top 0.05s 0.2s linear, right 0.2s 0.25s ease-in,
    -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  -webkit-transition: top 0.05s 0.2s linear, right 0.2s 0.25s ease-in,
    -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  -o-transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.2s linear, right 0.2s 0.25s ease-in;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.2s linear, right 0.2s 0.25s ease-in;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.2s linear, right 0.2s 0.25s ease-in,
    -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}
.hamburger--emphatic.is-active .hamburger-inner {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background-color: transparent;
}
.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  -webkit-transform: translate3d(80px, 80px, 0) rotate(45deg);
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear,
    -webkit-transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: left 0.2s ease-out, top 0.05s 0.2s linear,
    -webkit-transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: left 0.2s ease-out, top 0.05s 0.2s linear,
    transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear,
    transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear,
    transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1),
    -webkit-transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear,
    transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1),
    -webkit-transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear,
    transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1),
    -webkit-transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -80px;
  top: -80px;
  -webkit-transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear,
    -webkit-transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: right 0.2s ease-out, top 0.05s 0.2s linear,
    -webkit-transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: right 0.2s ease-out, top 0.05s 0.2s linear,
    transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear,
    transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear,
    transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1),
    -webkit-transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear,
    transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1),
    -webkit-transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear,
    transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1),
    -webkit-transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic.is-active {
  opacity: 1;
  z-index: 1111;
}
.hamburger--emphatic.is-active .hamburger-inner::before,
.hamburger--emphatic.is-active .hamburger-inner::after {
  background-color: #ffb400;
}
.hamburger {
  opacity: 1;
  z-index: 111;
}
.header-social a{
  display: inline-block;
}
header .navbar-brand img{
  max-width: 215px;
  position: relative;
  z-index: 1;
  transition: 0.5s;
}
header{
  position: fixed;
  top: 20px;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 10px 0px;
  transition: 0.3s;
}
header.fixed-header {
  top: 0px;
  background-color: rgb(6 12 5 / 70%);
  padding: 10px 0px;
  backdrop-filter: saturate(180%) blur(20px);
}
header .navbar-brand{
  position: relative;
  display: inline-block;
}
header .navbar-brand:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  background: linear-gradient(#ffb400 0%, #000 94.05%, #000 100%);
  opacity: 0.84;
  filter: blur(34px);
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.header-social li img {
  max-width: 58px;
}
.header-social .list-inline-item:not(:last-child) {
  margin-right: 12px;
}
header .navbar-nav li a {
  font-family: "Long Shot";
  font-weight: normal;
  font-size: 22px;
  letter-spacing: 0.04em;
  color: #fff !important;
  text-transform: uppercase;
  padding: 8px 35px !important; 
  display: inline-block;
}
/* header .navbar-nav li a:not(.active){
 overflow: hidden;
  background: linear-gradient(to right, #FFB400, #FFB400 50%, #fff 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
  text-decoration: none;
}
header .navbar-nav li a:hover{
background-position: 0 100%;
}*/
header .navbar-nav li a.active{
background-color: transparent;
background-image: url("../../public/images/button-bg.png");
background-repeat: no-repeat;
background-size: 100% 100%;
color: #954110 !important;
text-shadow: -2px 2px 0px #fdde09;
}
/* Header css end */
/* Banner section css start */
.banner-section{
  background-image: linear-gradient(rgb(15 15 13 / 0%) 0%, #0f0f0d 100%), url("../../public/images/hero-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  padding: 200px 0px 60px;
  position: relative;
}
.banner-subtitle {
    font-size: 23px;
    line-height: 1.6em;
}
.banner-heading{
  margin-left: -8%;
}
.joinus-btn {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 10%;
  right: 30px;
  z-index: 10;
}
.joinus-btn-img {
  max-width: 110px;
}
.joinus-btn:hover img{
  animation: tada 0.8s ease-in-out;
}

/* Banner section css end */
/* Connect with us section css start */
.connect-section{
  background-image: linear-gradient(rgb(15 15 13 / 0%) 0%, #0f0f0d 100%),url("../../public/images/connect-with-us-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  padding: 70px 0px;
  overflow: hidden;
  position: relative;
}
.connect-us-blk{
  background-image: url("../../public/images/connect-input-bg.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 25% 27% 12%;
  position: relative;
  transform-style: preserve-3d;
}
.connect-us-blk:before,
.connect-us-blk:after{
  position: absolute;
  content:"";
  width: 66px;
  height: 274px;
  bottom: 91%;
  transform: translateZ(-1px);
  pointer-events: none;
}
.connect-us-blk:before{
  background-image: url("../../public/images/connect-hang-2.png");
  left: 3%;
}
.connect-us-blk:after{
  background-image: url("../../public/images/connect-hang-1.png");
  right: 3%;
}
.signup-social-blk img {
  max-width: 70px;
}
.signup-blk .btn-primary {
  font-size: 20px;
  padding: 12px 44px;
  margin-left: -20px;
  z-index: 1;
}
.signup-blk .form-control,
.signup-blk .form-control:focus {
  background-color: transparent;
  border: none;
  color: #FFB400;
  font-size: 18px;
  font-weight: bold;
  height: 54px;
  background-image: url("../../public/images/signup-input-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 0;
}
.signup-blk .main-title--small,
.signup-blk .input-group{
  margin-bottom: 40px;
}
.signup-blk .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #FFB400;
}

.signup-blk .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #FFB400;
}

.signup-blk .form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: #FFB400;
}
.signup-social-blk .list-inline-item:not(:last-child) {
  margin-right: 14px;
}
.signup-social-blk li a{
  display: inline-block;
}
.connect-floating-icon {
  display: inline-flex;
  position: absolute;
  transform: translatey(0px);
  pointer-events: none;
  z-index: 1;
}
.connect-icon-1 {
  top: 10%;
  left: 10%;
  width: 6%;
  animation: float 4s ease-in-out alternate infinite;
}
.connect-icon-2 {
  left: 45%;
  top: 20%;
  z-index: 0;
  width: 5%;
  animation: float 8s ease-in-out reverse infinite;
}
.connect-icon-3 {
  top: 5%;
  right: 10%;
  width: 6%;
  animation: float 6s ease-in-out alternate infinite;
}
.connect-icon-4{
  bottom: 10%;
  left: 10%;
  width: 6%;
  animation: float 6s ease-in-out reverse infinite;
}
.connect-icon-5 {
  bottom: 5%;
  right: 10%;
  width: 17%;
  animation: float 8s ease-in-out alternate infinite;
}
/* Connect with us section css end */
/* Book section css start */
.book-section{
  background-image: linear-gradient(rgb(15 15 13 / 0%) 0%, #0f0f0d 100%),url("../../public/images/book-section-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  padding: 70px 0px;
  overflow: hidden;
  position: relative;
}
.main-title--small.book-title img {
  max-width: 70px;
  margin-right: 25px;
}
.main-title--small.book-title {
  font-size: 38px;
  margin-bottom: 40px;
}
.flipbook-content-blk {
  /* position: relative; */
  padding: 4% 0px 4%;
  box-sizing: border-box;
  /* max-width: 975px; */
  margin: 0 auto;
  background-image: url("../../public/images/book-bg.webp");
  background-size: 100% 100%;
}
.flipbook-blk {
  padding-top: 40px;
}
/*#flipbook {
  margin: 0 auto;
  background: linear-gradient(90deg, rgba(186,171,150,1) 0%, rgba(225,221,211,1) 7.5%, rgba(250,245,235,1) 25%, rgba(225,221,211,1) 42.5%, rgba(186,171,150,1) 50%, rgba(186,171,150,1) 50%, rgba(225,221,211,1) 50%, rgba(250,245,235,1) 75%, rgba(225,221,211,1) 85%, rgba(186,171,150,1) 100%);
  background-image: url("../../public/images/book-center.png");
  background-position: center top 30%;
  background-repeat: no-repeat;
  background-size:10% calc(93% + 2px);
  position: relative;
}*/
#flipbook {
  margin: 0 auto;
      background: linear-gradient( 
90deg, rgba(186,171,150,1) 0%, rgba(225,221,211,1) 15%, rgba(250,245,235,1) 40%, rgba(225,221,211,1) 45%, rgba(186,171,150,1) 50%);
  background-position: center top 30%;
  background-repeat: no-repeat; 
  position: relative;
}
.turn-page-wrapper,
.turn-page {
  background: rgb(186,171,150);
  background: linear-gradient(90deg, rgba(186,171,150,1) 0%, rgba(225,221,211,1) 15%, rgba(250,245,235,1) 50%, rgba(225,221,211,1) 85%, rgba(186,171,150,1) 100%);
  text-align: center;
}
.turn-page-wrapper .turn-page{
  position: relative;
}
/*#flipbook:before,
#flipbook .turn-page-wrapper .turn-page:before{
  position: absolute;
  content: "";
  width: 20px;
  height: 100%;
  background-image: url("../../public/images/page-1-before.png");
  left: 5px;
  top: 5px;
  background-repeat: no-repeat;
  background-size: auto 90%;
    background-position: center;
}*/
 
#flipbook .turn-page-wrapper .turn-page:before{
  position: absolute;
  content: "";
  width: 20px;
  height: 100%;
  background-image: url("../../public/images/page-1-before.png");
  left: 5px;
  top: 5px;
  background-repeat: no-repeat;
  background-size: auto 90%;
    background-position: center;
}

#flipbook:after,
#flipbook .turn-page-wrapper .turn-page:after,
#flipbook .turn-page-wrapper .turn-page:after{
  position: absolute;
  content: "";
  width: 20px;
  height: 100%;
  background-image: url("../../public/images/page-2-after.png");
  right: 5px;
  top: 5px;
  background-repeat: no-repeat;
  background-size: auto 90%;
  background-position: center;
}
/*#flipbook:before,
#flipbook .turn-page-wrapper:nth-child(even) .turn-page:before{
  position: absolute;
  content: "";
  width: 20px;
  height: 100%;
  background-image: url("../../public/images/page-1-before.png");
  left: 5px;
  top: 5px;
  background-repeat: no-repeat;
  background-size: auto 90%;
    background-position: center;
}
#flipbook .turn-page-wrapper:nth-child(even) .turn-page:after{
  position: absolute;
  content: "";
  width: 15%;
  height: 100%;
  background-image: url("../../public/images/page-1-after.png");
  right: -6%;
  top: 2%;
  background-repeat: no-repeat;
  background-size: 60% calc(94% + -2px);
}
#flipbook .turn-page-wrapper:nth-child(odd) .turn-page:before,
#flipbook .turn-page-wrapper:nth-child(1) .turn-page:before{
  position: absolute;
  content: "";
  width: 15%;
  height: 100%;
  background-image: url("../../public/images/page-2-before.png");
  left: 0px;
  top: 2%;
  background-repeat: no-repeat;
  background-size: 60% calc(94% + -2px);
  background-position: left top;
}
#flipbook:after,
#flipbook .turn-page-wrapper:nth-child(odd) .turn-page:after,
#flipbook .turn-page-wrapper:nth-child(1) .turn-page:after{
  position: absolute;
  content: "";
  width: 20px;
  height: 100%;
  background-image: url("../../public/images/page-2-after.png");
  right: 5px;
  top: 5px;
  background-repeat: no-repeat;
  background-size: auto 90%;
  background-position: center;
}*/
  
.flipbook .shadow{ 
  box-shadow: none;
  }
  .flipbook-nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: calc(100% + 60px);
    top: 50%;
    left: -30px;
}
.flipbook-nav #prevBtn:hover, .flipbook-nav #nextBtn:hover{
  animation: headShake 1.5s ease-in-out;
}

.wrapper {
  align-items: center;
  display: flex;
  height: 85%;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
}

.aspect {
  padding-bottom: 65%;
  position: relative;
  width: 100%;
}

.aspect-inner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.flipbook {
  height: 100%;
  transition: margin-left 0.25s ease-out;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flipbook .page {
  height: 100%;
  width: 100%;
}

.flipbook .page img {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}
/* Book section css end */
/* Mascota section css start */
.mascota-section{
  background-image: linear-gradient(rgb(15 15 13 / 0%) 0%, #0f0f0d 100%),url("../../public/images/mascota-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  padding: 70px 0px;
  overflow: hidden;
  position: relative;
}
.moscata-slider-thumb-blk {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.moscata-slider-thumb-img {
  padding: 20px;
  background-image: url("../../public/images/moscata-thumb.png");
  background-size: 100% 100%;
}
.moscata-slider-thumb-img img {
  max-width: 130px;
}
.moscata-slider-thumb-text{
font-family: "Long Shot";
font-size: 29px;
letter-spacing: 0.04em;
text-align: center;
color: #954110;
text-shadow: -2px 2px 0px #fdde09;
padding: 20px 60px;
background-image: url("../../public/images/moscata-thumb-button.png");
background-size: 100% 100%;
margin-top: -18px;
text-transform: uppercase;
}
.moscata-slider-main-img{
  text-align: center;
}
.moscata-slider-main-img img{
  max-width: 260px;
}
.moscata-slider-main {
  margin-bottom: 60px;
}
.mascota-slider-blk{
  background-image: url("../../public/images/moscata-stage.webp");
  background-size: 100% auto;
  padding: 80px 40px 0px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.moscata-slider-thumb{
  margin-top: -30px;
}
.swiper-slider-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  left: 0;
  transform: translateY(-50%);
}
.swiper-slider-nav img{
  cursor: pointer;
}
.swiper-slider-nav img:hover{
  animation: headShake 1.5s ease-in-out;
}
.swiper-thumb-blk {
  position: relative;
  padding: 0px 80px;
}
.swiper-thumb-blk .swiper-slider-nav {
  top: 70%;
}
.moscata-slider-thumb .swiper-slide {
  transform: scale(0.7) !important;
  transition: 0.3s;
}
.moscata-slider-thumb .swiper-slide.swiper-slide-active {
  transform: scale(1)  !important;
}
.moscata-slider-main .swiper-slide{
  opacity: 0 !important;
}
.moscata-slider-main .swiper-slide.swiper-slide-active{
  opacity: 1 !important;
}
/* Mascota section css end */
/* Dispara section css start */
.dispara-section{
  background-image: linear-gradient(rgb(15 15 13 / 0%) 0%, #0f0f0d 100%), url("../../public/images/dispara-section-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  padding: 120px 0px;
  overflow: hidden;
  position: relative;
}
.dispara-heading-blk{
  padding: 22px 40px 15px;
  background-image: url("../../public/images/dispara-heading-bg.png");
  background-size: 100% 100%;
    margin-bottom: 20px;
}
.lottie-animation-blk{
  background-image: url("../../public/images/guns-bg.png");
  background-size: 100% 100%;
}
.animation-start-btn{
  padding-bottom: 120px;
}
.gunanimations-btn-blk{
  padding-bottom: 110px;
}
/* Dispara section css end */
/* Formas section css start */
.formas-section{
  background-image: linear-gradient(rgb(15 15 13 / 0%) 0%, #0f0f0d 100%), url("../../public/images/formas-section-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  padding: 120px 0px;
  overflow: hidden;
  position: relative;
}
/* Formas section css end */
/* Who section css start */
.who-section{
  background-image: linear-gradient(rgb(15 15 13 / 0%) 0%, #0f0f0d 100%), url("../../public/images/who-section-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  padding: 120px 0px;
  overflow: hidden;
  position: relative;
}
.who-slider-blk, .who-slider-text {
  text-align: center;
}
.who-slider-text h2 {
  font-family: "Long Shot";
  font-weight: normal;
  font-size: 21px;
  letter-spacing: 0.04em;
  line-height: 16px;
  text-align: center;
  color: #954110;
  text-shadow: -2px 2px 0px #fdde09;
}
.who-slider-text h4 {
  font-family: "Long Shot";
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.04em;
  line-height: 15px;
  text-align: center;
  color: #954110;
  text-shadow: -1px 1px 0px #fdde09;
  margin-bottom: 0;
}
.who-slider-text{
  padding: 20px 30px;
  background-image: url("../../public/images/moscata-thumb-button.png");
  background-size: 100% 100%;
  margin-top: -18px;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
}
.who-swiper-slider-blk {
  padding: 40px 0px;
}
/* Who section css end */
/* Footer css start */
.partners-icons img {
  max-width: 130px;
}
.partners-icons .list-inline-item:not(:last-child) {
  margin-right: 14px;
}
.footer-section-blk {
  background-image: url("../../public/images/footer-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  padding: 70px 0px 0px;
  position: relative;
  overflow: hidden;
  background-color: #0F0F0D;
}
.partners-icons {
  padding-top: 40px;
}
.partners-icons li a {
  display: inline-block;
}
.footer-section {
  padding-top: 70px;
}
.footer-title {
  font-family: "Long Shot";
  font-weight: normal;
  font-size: 34px;
  letter-spacing: 0.04em;
  color: #ffb400;
  text-shadow: -3px 3px 0px #712f0a;
  margin-bottom: 30px;
}
.footer-logo-blk {
  margin-bottom: 50px;
}
.footer-social-icons li img {
  max-width: 50px;
}
.footer-links a {
  font-family: "Long Shot";
  font-weight: normal;
  font-size: 24px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  text-transform: uppercase;
  overflow: hidden;
  background: linear-gradient(to right, #FFB400, #FFB400 50%, #fff 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
  text-decoration: none;
}
.footer-links a:hover{
background-position: 0 100%;
}
.footer-links a img {
  margin-right: 12px;
}
.footer-links {
  column-count: 2;
}
.footer-links li{
  margin-bottom: 20px;
}

.footer-logo {
  position: relative;
  display: inline-block;
}
.footer-logo img{
  position: relative;
  z-index: 1;
}
.footer-logo:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  background: linear-gradient(#ffb400 0%, #000 94.05%, #000 100%);
  opacity: 0.84;
  filter: blur(34px);
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.copyright-link .list-inline-item:not(:last-child) {
  margin-right: 30px;
}
.copyright-link a{
  color: #fff;
  font-size: 14px;
  transition: 0.3s;
}
.copyright-link a:hover{
color: #ffb400;
}
.footer-copyright-section {
  font-size: 14px;
}
.footer-copyright-section .container{
  padding-top: 30px;
  border-top: 1px solid #FFB400;
  margin-top: 30px;
}
.footer-social-icons a {
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
}
/* Footer css end */

.pet-video{
  margin-top:100px;
}

/************************/
/* Responsive css start */
/************************/
/************************/
/* Responsive css end */
/************************/
@media only screen and (max-width:1199px) {
  body {
    font-size: 14px;
  }
  .banner-heading {
    max-width: 60%;
    margin-left: 0px;
}
.banner-character {
  max-width: 60%;
}
.btn-primary, .btn-primary:hover{
  font-size: 26px;
  padding: 8px 44px;
}
.moscata-slider-thumb-text {
  font-size: 20px;
  padding: 14px 40px;
}
.footer-social-icons li img,
.signup-social-blk img,
.main-title--small.book-title img {
  max-width: 50px;
}
.partners-icons img {
  max-width: 80px;
}
.footer-links a img {
  margin-right: 8px;
  max-width: 25px;
}
.swiper-slider-nav img,
.flipbook-nav img {
  max-width: 60px;
}
.main-title{
position: relative;
z-index: 1;
}
.formas-img-blk {
  max-width: 70%;
  margin: 0 auto;
}
.footer-logo img {
  max-width: 260px;
}
.main-title {
  font-size: 46px;
}
.main-title--small {
  font-size: 38px;
}
.footer-title{
  font-size: 26px;
}
.footer-links a{
  font-size: 20px;
}
.banner-subtitle {
  font-size: 18px;
}
#navbarSupportedContent {
  background-color: #000000de;
  z-index: 100;
  padding: 15px;
  border-radius: 0px 0px 15px 15px;
}
header .navbar-nav{
  margin-bottom: 10px;
}
header {
  top: 0px;
}
header .navbar-nav li {
  padding: 5px;
  border-bottom: 1px solid #402d00;
}
header .navbar-nav li a{
  padding: 0 !important;
  background: none;
  text-shadow: none;
}
header .navbar-nav li a.active {
  color: #ffb400 !important;
  padding: 0 !important;
  background: none;
  text-shadow: none;
}
.banner-section {
  padding: 140px 0px 60px;
}
header .navbar-brand img {
  max-width: 135px;
}
}
@media only screen and (max-width:992px) {
  .pet-video{
    margin-top: 30px;
  }
  .joinus-btn-img {
    max-width: 60px;
}
.dispara-heading-blk img {
  max-width: 30px;
}
.footer-section-blk{
  padding: 40px 0px 0px;
}
  .formas-section,
  .who-section{
    padding: 80px 0px;
  }
  .btn-primary, .btn-primary:hover {
    font-size: 18px;
    padding: 12px 44px;
}


  .main-title {
    font-size: 36px;
}
.main-title--small,
.main-title--small.book-title {
  font-size: 28px;
}
.who-slider-text h2{
  font-size: 18px;
}
.who-slider-text h4{
  font-size: 14px;
}
.flipbook-nav {
  width: 100%;
  top: 50%;
  left: 0;
}
}
@media only screen and (max-width:767px) {
  header .navbar-brand img {
    max-width: 115px;
}
.banner-section {
  padding: 160px 0px 60px;
}
.signup-blk .input-group {
  flex-direction: column;
}
.signup-blk .input-group input{
  width: 100%;
}
.swiper-slider-nav img, .flipbook-nav img {
  max-width: 60px;
}
header.fixed-header {
  padding: 0px 0px;
}

}


@media only screen and (max-width:575px) {
  .pet-video{
    margin-top: 100px;
  }
.signup-blk .main-title--small, .signup-blk .input-group {
  margin-bottom: 20px;
}
.mascota-slider-blk {
  padding: 40px 0px 0px;
}
.swiper-thumb-blk {
  position: relative;
  padding: 0px 40px;
}
.swiper-slider-nav img, .flipbook-nav img {
  max-width: 40px;
}
.connect-floating-icon {
  display: none;
}
.flipbook-nav {
  width: calc(100%);
  top: 64%;
  left: 0;
}
.footer-logo img {
  max-width: 170px;
}
}

.sepia{

  -webkit-filter: sepia(0.1%) sepia(100%) hue-rotate(345deg);
  -webkit-transition: all 0.6s ease;
  -webkit-backface-visibility: hidden;
}
.sepia:hover{
  filter: none;
  -webkit-filter: sepia(0%);
}